import { HandPricingProductDetails } from '@app/order/models/order-view-model';
import { List } from '@usf/list-types';
import {
  ListState,
  MasterListItemProductState,
  MasterListState,
} from '@usf/ngrx-list';
import {
  ItemTypes,
  ProductRow,
} from '../../lists/shared/list-detail-management/model/list-detail-management-view.model';
import { UsfProductCardModeEnum } from '../constants/usf-product-card-mode.enum';
import { ProductDetailViewModel } from '@product-detail/models/product-detail-view-model';

export interface UsfProductCardViewModel {
  mode: UsfProductCardModeEnum;
  product: ProductRow;
  list?: List;
  itemType?: ItemTypes;
  index?: number;
  isSelected?: boolean;
  pricingDisabled?: boolean;
  isOnline?: boolean;
  allowHandPricing?: boolean;
  handPricingDetails?: HandPricingProductDetails;
  checkboxDisabled?: boolean;
  showConfirmQuantityButton?: boolean;
}

export enum UsfProductCardDesktopHeights {
  baseHeight = 138, // product card + margin
  guestBaseHeight = 138,
  baseSplitHeight = 155.5, // product card + margin
  drawerHeight = 40,
  tagsHeight = 32, // tags
  splitTagsHeight = 6, // split card tags
  splitPromotionsHeight = 2.5,
  noteAndTagsHeight = 32, // note/tags + margin
  promotionsHeight = 29.3,
  warningBorderHeight = 2,
  splitWarningBorderHeight = 1,
  noteHeight = 28,
  tagsWithLineNumberHeight = 2.7,
  lineNumberHeight = 29.3,
  splitLineNumberHeight = 22.59,
  splitSearchTagsHeight = 28.09,
  splitSearchPromotionsHeight = 30.5,
  worksWellWithHeight = 102,
  baseListManagementHeight = 138.59,
  splitListManagementHeight = 212.59,
  notFoundListManagementHeight = 180,
  breakableListManagementHeight = 166.19,
  tagsListManagementHeight = 170,
  ratingsHeight = 21,
}

export enum UsfProductCardTabletHeights {
  baseHeight = 231.195,
  guestBaseHeight = 140.717,
  customerProductNumberHeight = 10,
  splitCustomerProductNumberHeight = 15,
  baseSplitHeight = 363.985,
  drawerHeight = 40,
  tagsWithLineNumberHeight = 5.5,
  alternativeTagsHeight = 32,
  stat0OutOfStockQtyBoxesHeight = 91.195,
  warningBorderHeight = 2,
  lineNumberHeight = 26.5,
  splitLineNumberHeight = 20.5,
  worksWellWithHeight = 84.4,
  baseListManagementHeight = 230.19,
  splitListManagementHeight = 356.97,
  notFoundListManagementHeight = 252.59,
  notFoundListDetailHeight = 208.5,
  ratingsHeight = 21,
}

export enum UsfProductCardMobileHeights {
  baseHeight = 215.195, // product card + margin
  guestBaseHeight = 122.117,
  baseHeightNoQtyBox = 190.09, // product card + margin - qty box height
  customerProductNumberHeight = 11, // height of cust prod number dropdown
  splitCustomerProductNumberHeight = 18,
  baseSplitHeight = 334.595, // product card + margin
  baseSplitHeightWithCustProdNumber = 353.49, // product card + margin\
  drawerHeight = 40,
  noteHeight = 30, // note + margin
  promotionsHeight = 38, // banner
  tagsHeight = 20, // tags
  alternativeTagsHeight = 32, // alternative tags
  stat0OutOfStockQtyBoxesHeight = 91.195,
  warningBorderHeight = 2,
  lineNumberHeight = 26.5,
  splitLineNumberHeight = 20.5,
  worksWellWithHeight = 85.0,
  baseListManagementHeight = 244.19,
  splitListManagementHeight = 357.59,
  notFoundListManagementHeight = 266.59,
  notFoundListDetailHeight = 222.5,
  ratingsHeight = 5,
  splitRatingsHeight = 20,
  extendedResolutionMsgHeight = 11.797,
}

export enum UsfBetterBuyCardHeights {
  drawerHeight = 30,
  desktopTwoQtyBoxesHeight = 240,
  desktopOneQtyBoxOneAdditionalRowHeight = 226,
  desktopOneQtyBoxHeight = 206,
  touchBaseHeight = 277.59,
  touchAdditionalRowHeight = 36,
  savingsBoxHeight = 42,
  savingsBoxWithOneLineHeight = 26,
  mobileBaseHeight = 293.59,
}

export enum UsfNoQtyBoxWithLineNumberHeights {
  baseDesktop = 167,
  baseTablet = 226,
  baseMobile = 210,
  isChecked = 4,
  tagsDesktop = 3,
  tagsTablet = 5,
  tagsMobile = 20,
  promotionsMobile = 38,
  drawerHeight = 40,
  warningBorderHeight = 2,
  tabletNotFound = 207.6,
  mobileNotFound = 191.6,
}

export interface ProductClickData {
  productNumber: number;
  isQuickView: boolean;
  currentProduct?: boolean;
  betterBuyCard?: boolean;
  product?: ProductDetailViewModel;
}

export interface ProductSelectionData {
  productNumber: number;
  value: boolean;
  masterListItem?: MasterListItemProductState;
}

export interface EllipsisClickData extends ProductQuickViewModel {
  event: Event;
  mode: UsfProductCardModeEnum;
  itemType: ItemTypes;
  list: ListState;
  pricingDisabled: boolean;
  isSelected: boolean;
  allowHandPricing?: boolean;
  handPricingDetails?: HandPricingProductDetails;
  masterList?: MasterListState;
  listItemNoteFeatureFlag?: boolean;
  mslCompareAlternatesFeatureFlag?: boolean;
  recentlyPurchasedDeleteFlag?: boolean;
}

export interface ProductQuickViewModel {
  product: ProductRow;
  isTouch: boolean;
}

export interface ResequenceProductData {
  newSeq: number;
  oldSeq: number;
  productNum: number;
}

export type ReplaceFunc = (...arg: any) => any;
